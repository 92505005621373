@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../font/Poppins-Light.f41b63c0bb0963ace821.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../font/Poppins-Regular.8081832fc5cfbf634aa6.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: url(../font/Poppins-Italic.ce3fff31c0f262aca227.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../font/Poppins-Medium.9e1bb626874ed49aa343.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(../font/Poppins-SemiBold.cce5625b56ec678e4202.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../font/Poppins-Bold.404e299be26d78e66794.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(../font/Poppins-ExtraBold.4ed78cd5d64af4efc3c7.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(../font/Poppins-Black.eacee5bb9c7fee5939ae.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../font/Raleway-Light.f25e9b3a1fc8e48e53f5.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../font/Raleway-Regular.9ade142e81dd8d55cd32.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(../font/Raleway-Italic.2a3303d3281dbe80bec6.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../font/Raleway-Medium.1af9f1e9f3fd4766df83.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(../font/Raleway-SemiBold.243af299ad3631bfc369.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../font/Raleway-Bold.d1a96e2aa6257c864d4d.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(../font/Raleway-ExtraBold.ac7bcb8a0f4abc1d6591.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(../font/Raleway-Black.eef1ff812c4eced3a276.ttf) format('truetype');
}
html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}
:root{
    --heading-color: #2E2056;
}

.grid {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
}

.col1-2 {
    grid-template-columns: 1fr 2fr;
}
.col2 {
    grid-template-columns: 1fr 1fr;
}
.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
}
body p {
    font-size: 16px;
    line-height: 30px;
    color: #5A5076;
    margin-bottom: 20px;
    margin-top: 20px;
}
body p+p {
    margin-bottom: 10px;
    margin-top: 10px;
}

h1, h2, h3, h4, .home-table thead, .home-table tbody .t-site-link {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 15px;
}

body, .home-table tbody {
    font-family: 'Raleway', sans-serif;
}

h1 {
    font-weight: 600;
    font-size: 52px;
    color: #FFFFFF;
    margin-top: 33px;
    margin-bottom: 34px;
}

h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 136.4%;
    color: var(--heading-color);
}

h2 b {
    font-weight: 600;

}
h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 136.4%;
    color: var(--heading-color);
}
h4{
    font-weight: 600;
    font-size: 22px;
    line-height: 136.4%;
    text-align: center;
    color: var(--heading-color);
}
a{
    color: #00A1D3;
    text-decoration: none;
}
.title-highlight {
    color: #00A1D3;
}

.d-none {
    display: none;
}

.text-center {
    text-align: center;
}

.m-center {
    margin: 0 auto;
}
.text-black{
    color: #00001C;
}
a.text-underline {
    text-decoration: underline;
}
.text-white {
    color: #fff;
}


/* Banner */
.banner-container {
    position: relative !important;
    z-index: 11 !important;
}
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    margin-bottom: 25px;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }

.site-header {
    position: relative;
    background: #000024;
}
.site-header::after{
    content: "";
    background-image: url(../image/header.webp);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    position: fixed;
    top: 0;
    right: 0;
    width: 70%;
    height: 1300px;
}
.site-header .content-wrapper {
    position: relative;
    z-index: 10;
    width: 70%;
}

.site-header .container {
    position: relative;
    z-index: 10;
}
.header-circle {
    position: absolute;
    filter: blur(222px);
}

.circle-1 {
    width: 640px;
    height: 640px;
    left: -382px;
    top: -280px;
    background: rgba(0, 104, 252, 0.24);
}

.circle-2 {
    position: absolute;
    width: 20%;
    height: 20%;
    top: 19%;
    left: 39%;
    right: 0;
    bottom: 0;
    background: #302259;
    filter: blur(100px);
}

.bullets {
    margin: 30px auto;
    list-style: disc;
    display: table; 
}
.bullets li, .bullets2 li{
    font-weight: bold;
    font-size: 16px;
    line-height: 36px;
}

.bullets2 {
    list-style: disc;
    padding-bottom: 35px;
    text-indent: 22px;
    margin-top: 30px;
}
.bullets2 li{
    color: #5A5076;
}
.bullets-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.bullets-wrapper .bullets2:first-child {
    margin-right: 166px;
}

ul.list-highlights {
    text-align: left;
    list-style: none;
    font-weight: 500;
}

ul.list-highlights li::before {
    content: "\2022";
    font-family: Poppins;
    color: #72dbf6;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
.col3{
    grid-template-columns: 1fr 1fr 1fr;
}
.header-description {
    line-height: 30px;
    padding-bottom: 20px;
    margin-bottom: 0;
    color: #FFFFFF;
    width: 75%;

}

.header-texts {
    margin-bottom: 72px;
}

.read-more-wrapper {
    margin-top: 0px;
}

.read-more {
    color: #00A1D3;
    font-family: Raleway;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 30px;
}

.read-more:hover {
    cursor: pointer;
}

.logo-wrapper {
    margin-bottom: 14px;
}
.site-logo{
    text-decoration: none;
    color: white;
    font-size: 18px;
    line-height: 27px;
}
footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #828C98;
}

.gradient-btn .inner {
    position: relative;
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 69px;
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    transition: font-size 0.4s linear;
    background: linear-gradient(95.18deg, #FF35E6 23.23%, #9F008F 85.02%);;
}

.gradient-btn .inner:after {
    content: '';
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    transition: opacity .4s linear;
    background: linear-gradient(95.18deg, #9F008F 14.98%, #FF35E6 72.74%);
    box-shadow: 0px 8px 73px -11px rgba(142, 73, 255, 0.5);
    border-radius: 69px;
}

.gradient-btn:hover .inner:after {
    opacity: 1; 
}

.gradient-btn  {
    padding: 3px;
    border-radius: 69px;
    background: transparent;
    box-shadow: 0px 35px 73px -11px rgba(102, 84, 131, 0.5);
    width: 354px;
    height: 82px;
}

.gradient-btn a , .gradient-btn span{
    color: white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.header-button {
    padding-bottom: 123px;
    width: 259px;
    height: 27px;
    left: 351px;
    top: 678px;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
}

.slide-down {
    height: 0;
    overflow: hidden;
    transition: height 0.2s linear;
}

.main {
    padding-top: 75px;
    position: relative;
    z-index: 10;
    background: #fff;
}

#best {
    position: relative;
}

#best::before {
    content: ' ';
    width: 110px;
    height: 154px;
    background-image: url(../image/arrows-1.png);
    display: block;
    position: absolute;
    left: 42px;
    top: 0;
}

#best::after {
    content: ' ';
    width: 203px;
    height: 283px;
    background-image: url(../image/arrows-2.png);
    display: block;
    position: absolute;
    right: -18px;
    top: 0;
}

/* Home table styles ↓↓↓ */

.t-section {
    padding: 15px;
    margin: 30px 0;
}

.t-tabs ul {
    list-style: none;
    margin-bottom: 60px;
    text-align: center;
}

.t-tabs ul li {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 19px rgba(46,32,86,.1);
    display: inline-block;
    margin: 0 20px;
    position: relative;
    transition: margin .2s linear;
}

.t-tabs ul li a {
    display: inline-block;
    width: 240px;
    height: 72px;
    line-height: 80px;
    text-decoration: none;
    text-align: center;
    margin-bottom: 3px;
    margin-left: 10px;
    margin-right: 10px;
}

.t-tabs ul li a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    color: #2E2056;
    border-bottom: 4px solid #AA5DDF;
    transition: border 0.2s linear;
}

.t-tabs ul li.active a, 
.t-tabs ul li a:hover {
    border-bottom: 4px solid #FFC737;
}

.t-tabs ul li.active a::after {
    border-top: 12px solid #FFC737;
    top: 93%;
    z-index: 2;
}

.t-tabs ul li.active::after {
    border-top: 12px solid #fff;
    top: 100%;
    z-index: 1;
}

.t-tabs ul li.active::after, 
.t-tabs ul li.active a::after {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    clear: both;
    content: '';
    height: 0;
    width: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
}

.t-section .tab {
    height: 0;
    opacity: 0;
    overflow: hidden;

}

.t-section .tab.active {
    height: auto;
    opacity: 1;
    transition: opacity 0.3s linear;
}

.home-table {
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0 10px;
    font-size: 14px;
    color: #2E2056;
    background-color: #000021;
    border-radius: 26px;
    padding: 17px;
}

.home-table thead {
    color: #96A0AE;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
}

.home-table td {
    border: solid 1px #000;
    border-style: solid none;
    padding: 6px;
    background-color: #F9FBFF;
}

.home-table td:first-child {
    border-left-style: solid;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
}

.home-table td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 28px;
    border-top-right-radius: 28px;
}

.home-table td:nth-of-type(2) {
    font-weight: bold;
}

.home-table td:nth-of-type(3) ul li:not(:last-child) {
    margin-bottom: 15px;
}

.home-table td:nth-of-type(4) {
    font-family: Poppins;
    font-weight: 600;
    font-size: 16px;
}

.home-table td .rating-img {
    width: 23px;
    height: 23px;
    background-image: url(../image/star.png);
    float:left;
    margin-right: 14px;
}

.home-table li {
    margin-left: 20px;
}

.home-table .t-logo:hover,
.home-table .t-site-link:hover {
    cursor: pointer;
}

.home-table .t-logo {
    background-color: #F1F4F9;
}

.home-table .gradient-btn{
    width: 181px;
    height: 64px;
    line-height: 64px;
    box-shadow: none;
    text-decoration: none;
    margin: 0 auto;
}
.home-table .gradient-btn .inner:after{
    background: linear-gradient(94.3deg, #46B588 -1.78%, #02C2FF -1.77%, #58D7FF 84.81%);
}
.home-table .gradient-btn .inner .btn{
    font-size: 16px;
    line-height: 24px;
}
.home-table .t-logo {
    height: 91px;
    line-height: 91px;
    width: 210px;
    text-indent: -5000px;
    background-repeat: no-repeat;
    background-position: center;
}

.home-table .t-logo, 
.home-table .t-site-link {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    border-radius: 25px;
    color: #FFFFFF;
}

.home-table tr:hover .t-site-link {
    background: #FFC737;
}

.home-table tr:hover td {
    border-top: solid 1px #FFC737;
    border-bottom: solid 1px #FFC737;
}

.home-table tr:hover td:first-of-type {
    border-left: solid 1px #FFC737;
}

.home-table tr:hover td:last-of-type {
    border-right: solid 1px #FFC737;
}

#nft-slot-casino tr:nth-of-type(1) .t-logo {
    background-image: url(../image/visualise.png);
}

#nft-slot-casino tr:nth-of-type(2) .t-logo {
    background-image: url(../image/decentraland.png);
}

#nft-slot-casino tr:nth-of-type(3) .t-logo {
    background-image: url(../image/fundamentalvr.png);
}

#nft-slot-casino tr:nth-of-type(4) .t-logo {
    background-image: url(../image/olivex.png);
}

#nft-slot-casino tr:nth-of-type(5) .t-logo {
    background-image: url(../image/staratlas.png);
}

.what-is {
    background: #f5f4f7;
    border-radius: 30px;
    margin: 70px 0 60px;
}

.what-is .inner-img {
    display: flex;
    aspect-ratio: 0.85;
}
.what-is .inner-img img{
    width: 100%;
    height: auto;
    object-fit: contain;
}
.what-is .inner {
    padding: 40px 40px 20px 10px;
    text-align: left;
}
.meta-nft-content {
    text-align: center;
    margin-bottom: 50px;

}
section ul {
    list-style: none;
}

.custom-ul li:before {
    content: '\2713';
    margin-right: 16px;
    color: #A3EF7C;
}

.custom-ul li:not(:last-child) {
    margin-bottom: 20px;
}

.block {
    margin: 50px 0;
    padding: 50px;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 11px 14px 60px rgba(46, 92, 118, 0.2);
}

.block .title {
    color: #261747;
    font-style: normal;
    font-weight: 600;
}

.block .content p:not(:last-child) {
    font-size: 17px;
    line-height: 30px;
    color: #63587B;
    margin-bottom: 35px;
}

.block .content .step-title {
    font-weight: 700;
    margin-bottom: 8px !important;
}

.container.bottom .block {
    background: #00A1D3;
    position: relative;
    top: 50px;
  
}

.container.bottom .title,
.container.bottom .content p,
.container.bottom .content a {
    color: #fff !important;
}

/* Games */
.games{
    border-top: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
    margin: 50px 0;
    padding: 50px 0;
}
.games .box{
    display: inline-table;
    text-align: center;
    padding: 0 15px;
}
.games .box h3{
    height: 70px;
}
.games .box img{
    padding: 40px 0;
    max-width: 100%;
    height: auto;
}
.games .gradient-btn{
    width: 100%;
    box-shadow: none;
}

.h-with-img{
    background-color: transparent;
    background-position: 0;
    background-repeat: no-repeat;
    background-size: auto;
    background-size: contain;
}
.h-with-img h3{
    left: 40px;
    position: relative;
}
.h-with-img.first-icon{    
    background-image: url(../image/virtual-reality.png);
}
.h-with-img.second-icon{    
    background-image: url(../image/investing.png);
}
.h-with-img.third-icon{    
    background-image: url(../image/ethereum.png);
}

/* Projects */

.projects-box{
    background: #000021;
    padding: 100px 0 80px;
    position: relative;
    top: -100px;
    z-index: -1;
}
.projects-box .container{
   padding: 0 50px;
}
.projects-box h2{
    color: white;
}
.projects-box p{
    color: #D7CEEE;
}
.projects-box .links{
    display: flex;
    justify-content: space-evenly;
    margin: 50px 0;
}
.projects-box .links a::before{
    content: "\2022";
    font-family: Poppins;
    color: #00A1D3;
    font-weight: bold;
    display: inline-block;
    width: 4em;
}

/* FAQ styles ↓↓↓ */
.faq-heading {
    margin-bottom: 38px !important;
    padding-top: 68px !important;
}

.faq:not(:first-of-type) {
    border-top: 1px solid #E5EFF9;
}

.faq.expanded+.faq {
    border-top: 0px;
}

.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-title h3 {
    color: #261747;
    font-weight: normal;
    font-size: 28px;
    height: 98px;
    line-height: 98px;
    pointer-events: none;
}

.faq-btn {
    width: 49px;
    height: 49px;
    border: 0;
    border-radius: 25px;
    background: rgb(246,246,246);
    background: linear-gradient(342deg, rgba(246,246,246,1) 42%, rgba(233,241,249,1) 69%);
    margin-right: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 42px;
}

.faq.expanded .faq-btn {
    background: linear-gradient(342deg, rgba(233,241,249,1) 42%, rgba(246,246,246,1) 69%);
}

.faq-btn:hover, .faq-title:hover {
    cursor: pointer;
}

.faq-btn:hover {
    background-color: #e9eef5;
    border-radius: 25px;
    transition: background-color 0.2s ease;
}

.faq {
    padding-left: 56px;
    transition: background-color 0.2s linear, font-weight 0.2s linear, border-radius 0.2s linear;
}

.faq .faq-body {
    font-size: 17px;
    line-height: 24px;
    color: #404447;
    padding-right: 142px;
    padding-bottom: 44px;
}

.faq.collapsed .faq-body {
    display: none;
}

.faq.expanded {
    background-color: rgba(229, 239, 249, 0.8);
    border-radius: 15px;
}

.faq-btn span {
    pointer-events: none;
}

.faq.collapsed .faq-btn span {
    color: #FF35E6;
 }

.faq.expanded .faq-btn span {
    color: black;
}

.faq.expanded .faq-btn span.minus,
.faq.collapsed .faq-btn span.plus {
    display: block;
}

.faq.collapsed .faq-btn span.minus,
.faq.expanded .faq-btn span.plus {
    display: none;
}

.faq.expanded .faq-title h3 {
    font-weight: 700;
}

.container.bottom {
    color: white;
}
.container.bottom .block{
    margin: 40px 0 0;
}
.container.bottom ul {
    list-style: none;
    margin-left: 36px;
}

.container.bottom ul li:before {
    content: "\2022";  
    color: #6165D9; 
    font-weight: bold; 
    font-size: 12px;
    display: inline-block; 
    width: 3em; 
    margin-left: -3em; 
}

.container.bottom ul li {
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 24px;
}

.contact-btn.gradient-btn {
    background: none;
    height: 69px;
    margin: 0 auto;
    position: relative;
    width: 200px;
    box-shadow: none
}
.contact-btn.gradient-btn .inner{
    background: linear-gradient(
        94.3deg, #46B588 -1.78%, #02C2FF -1.77%, #58D7FF 84.81%);
}

/*
.contact-btn.gradient-btn:hover:before {
    align-items: center;
    background: #fff;
    border-radius: 2px;
    border-radius: 69px;
    bottom: 0;
    box-shadow: 0 4px 15px rgb(26 70 79 / 30%);
    color: #4a4a4a;
    content: "Services under construction";
    display: flex;
    font-size: 16px;
    font-weight: 700;
    height: inherit;
    justify-content: center;
    left: 0;
    line-height: 27px;
    opacity: .95;
    position: absolute;
    text-align: center;
    transform: translateY(-80px);
    width: inherit;
}
.contact-btn.gradient-btn:hover:after {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 8px solid #fff;
    clear: both;
    content: "";
    height: 0;
    left: 43%;
    opacity: .95;
    position: absolute;
    top: 3px;
    transform: translateY(-16px);
    width: 0;
}
*/

/* Zoom image */
.zoomable-img{
    position: relative;
}
.zoomable-img:hover{
    filter: brightness(30%);
    cursor: zoom-in;
}
.zoomable-image-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.897);
    cursor: zoom-out;
}
.zoomable-image-overlay img{
    width: 100%;
    max-width: 900px;
    cursor: auto;
}
.close-btn{
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
}
.close-btn::after{
    content: "\00d7";
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    color: white;
    font-size: 26px;
}


/* Footer */
.footer {
    background: #1D1535;
    padding: 100px 0 20px;
    color: #9A91B6;
    position: relative;
    z-index: 1;
}

.footer-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}
@media (min-width: 1400px) {
    .site-header::after {
        background-position: 150%;
        height: 900px;
    }
    .col2 {
        grid-template-columns: 2fr 3fr;
    }
    .container {
        min-width: 1350px;
    }
    .games .box:first-of-type img, .games .box:last-of-type img{
        padding: 40px 0;
    }
    .zoomable-image-overlay img{
        max-width: 1100px;
    }
}
@media (max-width: 1025px){
    #best::after{
        right: 0;
    }
    .container {
        padding-left: 25px!important;
        padding-right: 25px!important;
    }
    .what-is.grid.col1-2{
        grid-template-columns: 1fr;
    }
    .inner-img{
        display: block;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 868px) {

    h1 {
        font-size: 44px;
    }

    h2 {
        font-size: 26px;
    }
    
    header .subtitle {
        font-size: 18px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }
    
    .m-hidden {
        display: none;
    }

    .m-slide-down {
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s;
        height: 0;
        overflow: hidden;
    }

    .col1-2, .col2, .col2-1, .col3{
        grid-template-columns: 1fr;
    }
    .site-header::after{
        content: none;
    }

    .site-header .content-wrapper {
        width: 100%;
    }
    .header-description{
        width: 100%;
    }
    .header-button, .gradient-btn{
        width: 100%;
    }
    .header-circle{
        display: none;
    }
    .bullets{
        padding: 0 30px;
    }
    .main {
        padding-top: 42px;
    }

    #best::before, 
    #best::after {
        background-image: none;
    }
    .t-section {
        margin-top: 16px;
        margin-bottom: 0px;
        padding: 0;
    }

    .t-tabs {
        display: block;
        overflow: hidden;
    }

    .t-tabs ul {
        min-width: auto;
        width: 1200px;
        margin-bottom: 28px;
        text-align: initial;
    }

    .home-table tr {
        display: table;
        background: #f9fbff;
        border-radius: 50px;
        max-width: -webkit-fill-available;
        width: 100%;
        padding: 10px 16px;
        margin-bottom: 16px;
    }

    .home-table thead tr {
        display: none;
    }

    .home-table td {
        display: inline-table;
        border: none !important;
        padding: 0;
        position: relative;
    }

    .home-table td:nth-of-type(5) {
        width: 100%;
        max-width: 450px;
        border: none;
        display: block;
        margin: 0px auto;
        border-radius: 50px;
        padding-top: 6px;
    }

    .home-table td:nth-of-type(1),
    .home-table td:nth-of-type(2){
        width: 48%;
        padding-left: 4%;
    }

    .home-table td:nth-of-type(3) {
        width: 68%;
        padding-left: 4%;
    }

    .home-table td:nth-of-type(2)::before,
    .home-table td:nth-of-type(3)::before,
    .home-table td:nth-of-type(4)::before {
        color: #96a0ae;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        left: 18px;
        position: absolute;
        text-transform: uppercase;
        top: -12px;
        width: 45%;
    }

    .home-table li {
        margin: 0;
    }

    .home-table td:nth-of-type(2)::before {
        content: 'Type';
    }

    .home-table td:nth-of-type(3) {
        margin-top: 12px;
    }

    .home-table td:nth-of-type(3)::before {
        content: 'Highlights';
    }

    .home-table td:nth-of-type(4)::before {
        content: 'Rating';
    }

    .home-table .t-logo {
        width: 100%;
        background-size: 90%;
    }

    .home-table .t-logo,
    .home-table .t-site-link {
        height: 64px;
        line-height: 64px;
    }

    .home-table .t-site-link {
        width: 90%;
        margin: 0 auto;
        box-shadow: none;
    }
    .bullets-wrapper {
        display: grid;
    }
    .bullets-wrapper .bullets2:first-child {
        margin-right: 0;
    }
    .what-is .inner-img img {
        width: 100%;
    }
    
    .what-is .inner, .block {
        padding: 25px;
    }
    .games .box{
        padding: 0;
    }
    .projects-box .links{
        display: grid;
        justify-content: center;
        align-items: center;
        text-align: left;
    }
    .projects-box .links a::before{
        width: 2em;
    }
    .h-with-img h3{
        margin-left: 20px;
        width: 80%;
    }
    .h-with-img{
        background-size: 50px;
    }
    .close-btn {
        right: 0;
        top: 40px;
    }

    /* Mobile read more slide area ↓↓↓ */

    .m-readmore-btn {
        color: #2E2056;
        height: 60px;
        width: 100%;
        border-radius: 20px;
        border: 1px solid #2E2056;
        background-color: transparent;
        text-align: center;
        font-weight: 900;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 18px;
    }

    .m-readmore-btn:active {
        background-color: #fff0e6;
    }

    .m-readmore-btn .img {
        width: 16px;
        height: 18px;
        margin-left: 18px;
    }

    .m-readmore-btn span {
        pointer-events: none;
    }

    .m-slider-nav {
        width: 120px;
        margin: 8px auto 0px;
        padding-bottom: 20px;
    }

    .m-slider-nav ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
    }

    .m-slider-nav ul li {
        list-style-type: none;
        width: 7px;
        height: 7px;
        background-color: #DEDDE2;
        border-radius: 12px;
    }

    .m-slider-nav ul li.active {
        background-color: #FFC737;
    }

    .m-slider-img {
        height: 0;
        opacity: 0;
        transition: opacity 1s ease-out;
    }

    .m-slider-img.active {
        opacity: 1;
        height: auto;
    }

    .faq {
        padding-left: 20px;
    }

    .faq-title {
        padding-bottom: 12px;
        padding-top: 10px;
    }

    .faq-title h3 {
        font-size: 20px;
        line-height: 38px;
        padding-top: 10px;
        padding-right: 10px;
        height: auto;
    }

    .faq .faq-body {
        padding-right: 22px;
    }

    .faq-btn {
        flex-shrink: 0;
        margin-right: 12px;
    }

    footer .container {
        display: block;
        text-align: center;
        padding-bottom: 20px;
    }
    .footer-inner{
        display: grid;
        gap: 20px;
        justify-content: center;
    }
}


